

















































































































































































































































































































































































































































































































@import "../../assets/css/statistics";
.rightCount{
	width: calc(100% - 73%);
}
